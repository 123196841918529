import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/dashboard/dashboard";
import Availability from "../pages/availability/availability";
import GoOnDemand from "../pages/medium/goOnDemand/goOnDemand";
import MediumAppointments from "../pages/medium/appointments";
import MyProfile from "../pages/medium/myprofile";
import Feedback from "../pages/medium/feedbacks";
import Integration from "../pages/medium/integration";
import MediumView from "../pages/medium/myprofile/mediumprofileview";
import MyAvailability from "../pages/medium/availability";
import Transaction from "../pages/medium/transactions";
import Princing from "../pages/medium/princing";
import PayMyMedium from '../pages/paymymedium';
import ThankyouPopup from "../pages/client/appointment/premiumsessions/practitonerInfo/payment/thankyou";
import MediumBooking from "../pages/medium/booking";
import VerifyEmail from "../pages/admin/calendar/verifyemail";
import MediumProfileView from "../pages/medium/myprofile/mediumprofileview/profileview";
import AppointmentConfirm from "../pages/client/appointmentconfirm";
import ReferOthers from "../pages/medium/referothers";
import GoOnDemandPractice from "../pages/medium/goOnDemandPractice/goOnDemandPractice";
// import Survey from "../pages/surveyform";
// import Zoomhandler from "../pages/zoomhandler";

const MediumRouteApproved = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/appointments" />} />
      <Route path="/public-view" element={<MediumView />} />
      {/* <Route path="/dashboard" element={<Dashboard />} /> */}
      <Route path="/public-view/:id" element={<MediumProfileView show={false} />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />
      <Route path="/availability" element={<MyAvailability />} />
      <Route path="/sessions" element={<Availability />} />
      <Route path="/appointments" element={<MediumAppointments />} />
      <Route path="/my-profile" element={<MyProfile />} />
      <Route path="/go-on-demand" element={<GoOnDemand />} />
      <Route path="/go-on-demand-practice" element={<GoOnDemandPractice />} />
      <Route path="/refer-others" element={<ReferOthers />} />
      <Route path="/feedbacks" element={<Feedback />} />
      <Route path="/integration" element={<Integration />} />
      <Route path="/transactions" element={<Transaction />} />
      <Route path="/princing" element={<Princing />} />
      <Route path="/paymymedium/:id" element={<PayMyMedium />} />
      <Route path="/booking" element={<MediumBooking />} />
      <Route path="/thankyou" element={<ThankyouPopup />} />
      <Route path="/appointment-confirmation/:id" element={<AppointmentConfirm />} />

      {/* <Route path="/survey" element={<Survey />} /> */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default MediumRouteApproved;
