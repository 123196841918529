import React, { useState } from 'react';

const TimeInput = () => {
    const [times, setTimes] = useState(["", "", "", ""]);

    const handleTimeChange = (index, event) => {
        const newTime = event.target.value;
        const newTimes = [...times];

        // Convert the new time to a Date object
        const currentTime = new Date(`1970-01-01T${newTime}:00`);

        // Check if the new time is at least 15 minutes apart from all other times
        for (let i = 0; i < newTimes.length; i++) {
            if (i !== index && newTimes[i]) {
                const otherTime = new Date(`1970-01-01T${newTimes[i]}:00`);
                const differenceInMinutes = Math.abs((currentTime - otherTime) / (1000 * 60));

                if (differenceInMinutes < 15) {
                    // alert(`Time must be at least 15 minutes apart from time ${i + 1}.`);
                    return;
                }
            }
        }

        // Update the state if the new time is valid
        newTimes[index] = newTime;
        setTimes(newTimes);
    };

    return (
        <div>
            {times.map((time, index) => (
                <div className='mt-3' key={index}>
                    <label className='mr-3 w-70-px'>Time {index + 1}:</label>
                    <input
                        type="time"
                        value={time}
                        onChange={(e) => handleTimeChange(index, e)}
                        required
                    />
                   {index==3 && <button className='ml-5'>Submit</button>}
                </div>
            ))}
        </div>
    );
};

export default TimeInput;
