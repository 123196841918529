import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { fetchUser } from "../../services/auth";
import { setUser } from "../../reducer/userSlice";

import StepFour from "./profileStep/stepFour";
import StepOne from "./profileStep/stepOne";
import StepThree from "./profileStep/stepThree";
import StepTwo from "./profileStep/stepTwo";
import Notifiacation from "./notifiacation";
import {
  getLanguages,
  getSpeciality,
  getCountry,
  getTools,
  getTimezones,
} from "../../services/uidata";
import { setLoading } from "../../reducer/auth/auth";
import {
  getProfileBasicInfo,
  getProfilePreferences,
  getProfileTechnology,
} from "../../services/medium";
import { stepOneData, stepTwoData, stepThreeData, stepFourData } from "./data";

import "./profile.css";
// import { getUserCountry ,userSCountry} from "./utils/utils";

const Profile = () => {
  const dispatch = useDispatch();
  const { status, medium_status } = useSelector((state) => state.user.user);
  // const status = localStorage.getItem("status");
  // const user_status = localStorage.getItem("medium_status");
  const user_status = medium_status;

  const [step, setStep] = useState(0);
  const [values, setValues] = useState({
    stepOneData,
    stepTwoData,
    stepThreeData,
    stepFourData,
  });

  const [formLanguages, setFormLanguages] = useState([]);
  const [timezoneData, setTimezoneData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [specialtyData, setSpecialityData] = useState([]);
  const [profileSection, setProfileSection] = useState(null);
  const [teachnologySection, setteachnologySection] = useState(null);
  const [preferncesSection, setpreferncesSection] = useState(null);
  const [countryOnly, setcountryOnly] = useState(null);
  const [timezoneOnly, settimezoneOnly] = useState(null);
  const [recieved, setRecieved] = useState(false);
  const [tools, setTools] = useState([]);
  const [showTimeZoneError, setshowTimeZoneError] = useState(false);

  useEffect(() => {
    launchApi();
    dispatch(setLoading(true));
    setTimeout(() => {

      dispatch(setLoading(false));
    }, 2000);
  }, []);
  const launchApi = async () => {
    // await getZone();
    await callProfile();
    updateCountries();
    updateLanguage();
    updateSpeciality();
    updateTools();
    updateTimezones();

    if (profileSection == null) getProfile();

    if (teachnologySection == null) getTechnologies();
    if (preferncesSection == null) getPreferences();
  };
  // const getZone = async () => {
  //    await getUserCountry();
  // };
  useEffect(() => {
    if (stepTwoData.country_type === "All Countries with Exclusions") {
      stepTwoData.exclusions = getCountryTypes(
        stepTwoData.country_type,
        stepTwoData.countries,
        countryData
      );
    }
    if (stepTwoData.country_type === "Selected Countries only") {
      stepTwoData.inclusions = getCountryTypes(
        stepTwoData.country_type,
        stepTwoData.countries,
        countryData
      );
    }
  }, [recieved]);
  const callProfile = async () => {
    let token = localStorage.getItem("access_token");
    await fetchUser(token)
      .then((data) => {
        dispatch(setUser(data.data.data));
        // localStorage.setItem("user", JSON.stringify(data.data.data));
        // localStorage.setItem("medium_status", data.data.data.medium_status);
        // localStorage.setItem("status", data.data.data.status);
      })
      .catch((err) => console.log(err));
  };
  const updateTools = () => {
    getTools()
      .then((data) => {
        setTools(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const updateLanguage = () => {
    getLanguages()
      .then((data) => {
        setFormLanguages(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const updateCountries = () => {
    getCountry()
      .then((data) => {
        setCountryData(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const updateTimezones = () => {
    getTimezones()
      .then((data) => {

        setTimezoneData(data.data.data);

      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    console.log(timezoneData,"timezoneData" ,stepOneData.timezone);
    settimezoneOnly("");
    if ((stepOneData.timezone.id === "" || stepOneData.timezone=='') && timezoneData.length>0) {
      let newtime = moment.tz.guess()
      let item = timezoneData.filter((time) => time.name === newtime);
      stepOneData.timezone = { ...item[0], value: item[0].id, label: item[0].front_name };

    }
    settimezoneOnly("settimezoneOnly");

  }, [timezoneData ,stepOneData])
  // useEffect(() => {
  //   console.log(timezoneData, "timezoneData");
  //   if (timezoneData.length > 0) {

  //     settimezoneOnly(null);
  //     if (stepOneData.timezone.id === "" || stepOneData.timezone === "") {
  //       let newtime = moment.tz.guess()
  //       console.log('Detected Timzone', newtime)
  //       let item = timezoneData.filter((time) => time.name === newtime);
  //       console.log('filtered Timzone', item,)
  //       if (item.length && item[0].status === 1) {

  //         stepOneData.timezone = { ...item[0], value: item[0].id, label: item[0].front_name };
  //       }
  //       else {
  //         let info = getNextMatchingTimezone(timezoneData, newtime)
  //         console.log('Next 1 status timezone', info)
  //         stepOneData.timezone = { ...info, value: info.id, label: info.front_name };
  //       }


  //       settimezoneOnly("settimezoneOnly");
  //     }
  //     else {
  //       settimezoneOnly("settimezoneOnly");
  //     }
  //   }
  // }, [timezoneData])

  // const getNextMatchingTimezone = (timezoneData, newtime) => {
  //   const array = timezoneData;
  //   const currentValue = newtime;

  //   const currentIndex = array.findIndex(obj => obj.name === currentValue);
  //   const nextMatchingIndex = array.findIndex((obj, index) => index > currentIndex && obj.status === 1);
  //   const nextMatchingObject = nextMatchingIndex !== -1 ? array[nextMatchingIndex] : null;

  //   return nextMatchingObject;

  // }
  const updateSpeciality = () => {
    getSpeciality()
      .then((data) => {
        setSpecialityData(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const updateValues = (data, step) => {
    if (step === 1) {
      const new_obj = { ...values, stepOneData: data };
      setValues(new_obj);
    }
    if (step === 2) {
      const new_obj = { ...values, stepTwoData: data };
      setValues(new_obj);
    }
    if (step === 3) {
      const new_obj = { ...values, stepThreeData: data };
      setValues(new_obj);
    }
    if (step === 4) {
      const new_obj = { ...values, stepFourData: data };
      setValues(new_obj);
    }
  };

  const NextStep = () => step !== 3 && setStep(step + 1);

  const PreviousStep = () => step !== 0 && setStep(step - 1);

  const SelectValues = (mainClass, items) => {
    let eleme = document.getElementById(mainClass);
    if (eleme) {
      let element = document.getElementById(mainClass).firstChild;
      let innerElement = element.querySelectorAll(".search-choice");
      let length = innerElement.length;
      let item = Array.from(Array(length), (_, i) =>
        parseInt(innerElement[i].firstChild.children[0].innerHTML)
      );
      return item;
    }
    return [];
  };
  const checkTimeZoneName = () => {

    let demo = { id: "", name: 'flkreklcnejd' }

    return demo;
  }
  const getProfile = () => {
    getProfileBasicInfo()
      .then((data) => {

        const {
          country,
          first_name,
          last_name,
          info,
          languages,
          mediumship_level_medium,
          phone,
          specialities,
          website,
          whatsapp,
          timezone,
          medium_professional_category,
        } = data.data.data;
        let newtime = moment.tz.guess()
        if (timezone.front_name == "") {


          if (newtime === "" || newtime == null) {
            setshowTimeZoneError(true)
          }
        }
        stepOneData.firstName = first_name;
        stepOneData.lastName = last_name;
        stepOneData.website = website;
        stepOneData.country = country;
        stepOneData.biographicalInfo = info;
        stepOneData.phone = phone;
        stepOneData.mediumshipLevel = parseInt(mediumship_level_medium);
        stepOneData.languages = updateIncomingHandler(languages, "languages");
        stepOneData.mediumshipSpecialities = updateIncomingHandler(
          specialities,
          "specialities"
        );
        stepOneData.timezone = timezone.front_name !== "" && stepOneData.timezone.id !=="" ?
        { ...timezone, value: timezone.id, label: timezone.front_name }
        : stepOneData.timezone;
        // stepOneData.timezone = timezone.front_name !== "" ?
        //   { ...timezone, value: timezone.id, label: timezone.front_name } :
        //   { id: "", front_name: newtime };
        console.log(timezoneData, "timezoneData")
        stepOneData.whatsapp = 'whatsapp';
        stepOneData.medium_professional_category = medium_professional_category;
        setcountryOnly("setcountryOnly");
        settimezoneOnly("settimezoneOnly");
        setProfileSection(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  const getPreferences = () => {
    getProfilePreferences()
      .then((data) => {
        setRecieved(true);
        const {
          allow_recordings,
          countries,
          current_roles,
          mediumship_session_tools,
          payment_methods,
          session_message,
          session_preferences,
          session_type,
          screen_name,
          country_type,
        } = data.data.data;
        stepTwoData.mediumship_session_tools = updateIncomingHandler(
          mediumship_session_tools,
          "tools"
        );

        stepTwoData.allowRecordings =
          allow_recordings === "" ? 3 : allow_recordings;
        stepTwoData.interestedSessionType1 = session_message;
        stepTwoData.currentRoles = current_roles;
        stepTwoData.PaymentMethods = payment_methods.map((item) => {
          return item.payment_method_id;
        });
        stepTwoData.wantToDoSessions =
          session_preferences !== null ? session_preferences : [];
        stepTwoData.interestedSessionType =
          session_type !== null ? session_type : [];
        stepTwoData.screenName = screen_name;
        stepTwoData.country_type = country_type;
        stepTwoData.wantToDoWork =
          country_type === "" ? "All Countries" : country_type;
        stepTwoData.countries = countries;

        setpreferncesSection(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  const getTechnologies = () => {
    getProfileTechnology()
      .then((data) => {
        const {
          calendar_type,
          preferred_browser,
          preferred_device,
          zoom_account_type,
          zoom_personal_link,
          paypal_me_link,
        } = data.data.data;

        stepThreeData.electronicCalendars =
          calendar_type !== null ? calendar_type : [];
        stepThreeData.zoomAccountType = zoom_account_type;
        stepThreeData.preferredDevice =
          preferred_device !== null ? preferred_device : [];
        stepThreeData.preferredBrowser =
          preferred_browser !== null ? preferred_browser : [];
        stepThreeData.zoom_personal_link = zoom_personal_link;
        stepThreeData.paypal_me_link = paypal_me_link !== "" ? paypal_me_link : stepThreeData.paypal_me_link;
        setteachnologySection(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  const getCountryTypes = (type, countries, items) => {
    if (countries.length) {
      let country;
      if (type === "All Countries with Exclusions") {
        const res = items.filter(
          (entry1) =>
            !countries.some(
              (entry2) => parseInt(entry1.id) === parseInt(entry2.country_id)
            )
        );
        country = res.map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.country_name,
          };
        });
      }
      if (type === "Selected Countries only") {
        const res = items.filter((entry1) =>
          countries.some(
            (entry2) => parseInt(entry1.id) === parseInt(entry2.country_id)
          )
        );

        country = res.map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.country_name,
          };
        });
      }
      // stepTwoData.exclusions =
      //   type == "All Countries with Exclusions" ? country : "";
      // stepTwoData.inclusions = type == "Selected Countries only" ? country : "";
      return country;
    }
  };
  const updateIncomingHandler = (items, type) => {
    let incomingArray;
    if (type === "languages") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.language_id,
          value: item.language_id,
          label: item.lanugage_name,
        };
      });
    }
    if (type === "specialities") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.mediumship_specialities_id,
          value: item.mediumship_specialities_id,
          label: item.specialty_name,
        };
      });
    }
    if (type === "tools") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.mediumship_session_tool_id,
          value: item.mediumship_session_tool_id,
          label: item.tools_name,
        };
      });
    }
    return incomingArray;
  };
  const RenderedComponent = () => {
    switch (step) {
      case 0:
        return (
          <StepOne
            formLanguages={formLanguages}
            countryData={countryData}
            specialtyData={specialtyData}
            setValues={updateValues}
            value={values}
            stepOneData={values.stepOneData}
            stepTwoData={values.stepTwoData}
            SelectValues={SelectValues}
            NextStep={NextStep}
            PreviousStep={PreviousStep}
            countryOnly={countryOnly}
            timezoneData={timezoneData}
            timezoneOnly={timezoneOnly}
            showTimeZoneError={showTimeZoneError}
          />
        );
      case 1:
        return (
          <StepTwo
            tools={tools}
            formLanguages={formLanguages}
            countryData={countryData}
            specialtyData={specialtyData}
            setValues={updateValues}
            value={values}
            stepTwoData={values.stepTwoData}
            stepOneData={values.stepOneData}
            SelectValues={SelectValues}
            NextStep={NextStep}
            PreviousStep={PreviousStep}
          />
        );
      case 2:
        return (
          <StepThree
            formLanguages={formLanguages}
            countryData={countryData}
            specialtyData={specialtyData}
            setValues={updateValues}
            value={values}
            stepThreeData={values.stepThreeData}
            SelectValues={SelectValues}
            NextStep={NextStep}
            PreviousStep={PreviousStep}
          />
        );
      case 3:
        return (
          <StepFour
            setValues={updateValues}
            value={values}
            stepFourData={values.stepFourData}
            SelectValues={SelectValues}
            NextStep={NextStep}
            PreviousStep={PreviousStep}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <div className="from-saction">
      <div className="container">
        <div className="row justify-content-center">
          {status === 0 && user_status === 1 && <Notifiacation />}
          {status === 0 && user_status === 0 && (
            <div id="msform">
              <div className="header-from">
                <ul id="progressbar">
                  <li className="active" id="account">
                    <span>
                      <img
                        src="images/step-user-black.png"
                        className="black"
                        alt="user dark"
                      />
                      <img
                        src="images/step-user.png"
                        className="wight"
                        alt="user light"
                      />
                    </span>
                    <strong>Profile</strong>
                  </li>
                  <li className={step > 0 ? "active" : ""} id="personal">
                    <span>
                      <img
                        src="images/sating.png"
                        className="black"
                        alt="dark "
                      />
                      <img
                        src="images/sating-wight.png"
                        className="wight"
                        alt="light"
                      />
                    </span>
                    <strong>Preferences</strong>
                  </li>
                  <li className={step > 1 ? "active" : ""} id="payment">
                    <span>
                      <img
                        src="images/nanotechnology.png"
                        className="black"
                        alt="technoloy"
                      />
                      <img
                        src="images/nanotechnology-wight.png"
                        className="wight"
                        alt="technoloy"
                      />
                    </span>
                    <strong>My Technology</strong>
                  </li>
                  <li className={step > 2 ? "active" : ""} id="confirm">
                    <span>
                      <img
                        src="images/privacy.png"
                        className="black"
                        alt="dark"
                      />
                      <img
                        src="images/privacy-wight.png"
                        className="wight"
                        alt="light"
                      />
                    </span>
                    <strong>Legal and Privacy</strong>
                  </li>
                </ul>
              </div>
              <div className="body-from from-step-new">{RenderedComponent()}</div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="loader-overlay">
        <GridLoader />
      </div> */}
    </div>
  );
};

export default Profile;
