import React, { useEffect, useState } from "react";
import { Formik } from "formik";

import { useDispatch } from "react-redux";
import { updateInitialProfile } from "../../../services/profile";
import { setLoading } from "../../../reducer/auth/auth";
import Select from "react-select";

import {
  capatilizeLetter,
  filterVisibleTimeZones,
  filterZone,
  screenNameHandler,
} from "../../../utils/helpers";
import PhoneInputField from "../../../components/Inputfields/phoneinput";
const StepOne = (props) => {
  const [newformData, setNewFormData] = useState([]);
  const [newCountryData, setNewCountryData] = useState([]);
  const [newTimezoneData, setNewTimezoneData] = useState([]);
  const [userCountryCode, setUserCountryCode] = useState("US");
  const [showExpectionInfo, setshowExpectionInfo] = useState(false);
  const [timeZoneError, setTimeZoneError] = useState(false);
  const {
    NextStep,
    stepOneData,
    setValues,
    formLanguages,
    specialtyData,
    countryData,
    countryOnly,
    timezoneData,
    timezoneOnly,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    setNewFormData(
      formLanguages.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.name,
        };
      })
    );
    setNewTimezoneData(filterVisibleTimeZones(timezoneData));
    setNewCountryData(
      countryData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.country_name,
        };
      })
    );
    addDefault();
  }, ["", formLanguages, specialtyData, countryData, timezoneData]);

  const addDefault = () => {
    if (!stepOneData.languages.length > 0 && newformData.length) {
      stepOneData.languages.push(newformData[10]);
    }
  };

  const RunFunction = (formData) => {
    setValues(formData, 1);

    const {
      first_name,
      last_name,
      phone,
      whatsapp,
      languages,
      country,
      screen_name,
      timezone,
    } = formData;

    // if (timezone.id === "" && timezone.name === "") {

    //   dispatch(setLoading(false));
    //   setTimeZoneError(true)
    //   return;

    // }
    let lang = languages.map((item) => {
      return item.id;
    });
    let timezones =
      timezone.id === "" ? filterZone(timezone, timezoneData) : timezone.id;

    let item = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      whatsapp: whatsapp,
      country: country.label !== undefined ? country.label : country,
      languages: lang,
      timezone: timezones,
      screen_name: screen_name,
    };


    dispatch(setLoading(true));
    updateInitialProfile(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          NextStep();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const customHandleChange = (errors) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const capatilizeLetters = (e, name, setFieldValue, uservalues) => {
    let values = e.target.value;
    const input = capatilizeLetter(values);
    setFieldValue(name, input);

    if (uservalues.first_name !== "") {
      console.log(
        screenNameHandler(uservalues.first_name, uservalues.last_name)
      );
      setFieldValue(
        "screen_name",
        screenNameHandler(
          name === "first_name" ? e.target.value : uservalues.first_name,
          name === "last_name" ? e.target.value : uservalues.last_name
        )
      );
    }
    if (e.target.name === "first_name" && e.target.value === "") {
      setFieldValue("screen_name", "");
    }
  };
  const countryChangeHandler = (value, name, setFieldValue) => {
    setUserCountryCode(value.country_code);
    setFieldValue(name, value);
  };
  // const checkTat = () => {
  //   console.log(timezoneData, "timezoneData")
  //   let timezone = stepOneData.timezone;
  //   let check = timezoneData.filter((time) => time.name === timezone.name);
  //   if (check.length) {
  //     return
  //   } else {
  //     stepOneData.timezone = { id: '', name: '' }
  //     // setTimeZoneError(true)
  //   }


  // }
  return (
    <Formik
      initialValues={stepOneData}
      validate={(values) => {
        const errors = {};
        if (!values.first_name) errors.first_name = "Required";
        if (!values.last_name) errors.last_name = "Required";
        if (!values.languages.length) errors.languages = "Required";
        if (values.whatsapp && values.whatsapp.length < 10) {
          errors.whatsapp = "Invalid whatsapp";
        }
        if (!values.screen_name) errors.screen_name = "Required";
        if (!values.phone) {
          errors.phone = "Required";
        }
        if (values.phone && values.phone.length < 10) {
          errors.phone = "Invalid Phone";
        }
        if (!values.country) errors.country = "Required";
        if (!values.timezone) errors.timezone = "Required";
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        RunFunction(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          {addDefault()}
          {/* {checkTat()} */}
          <div className="from-inner-box">
            <div className="left-side">
              <label>
                First Name<i>*</i>
              </label>
              <input
                onChange={(e) =>
                  capatilizeLetters(e, "first_name", setFieldValue, values)
                }
                className={`${errors.first_name && touched.first_name && errors.first_name
                  ? "error-boundry"
                  : ""
                  }`}
                onBlur={handleBlur}
                value={values.first_name}
                type="text"
                id="first_name"
                name="first_name"
                placeholder="First Name"
              />
            </div>
            <div className="right-side">
              <label>
                Last Name<i>*</i>
              </label>
              <input
                className={`${errors.last_name && touched.last_name && errors.last_name
                  ? "error-boundry"
                  : ""
                  }`}
                onChange={(e) =>
                  capatilizeLetters(e, "last_name", setFieldValue, values)
                }
                onBlur={handleBlur}
                value={values.last_name}
                type="text"
                id="last_name"
                name="last_name"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="from-inner-box cuntrey-website">
            <div>
              <label>
                Country <i>*</i>
              </label>
              {countryOnly && (
                <Select
                  id="country"
                  className="selection-box"
                  options={newCountryData}
                  defaultInputValue={
                    typeof values.country == "object"
                      ? values.country.country_name
                      : values.country
                  }
                  onChange={(value) =>
                    countryChangeHandler(value, "country", setFieldValue)
                  }
                  onBlur={handleBlur}
                  placeholder="Country*"
                  value={values.country}
                  closeMenuOnSelect={true}
                  closeMenuOnScroll={true}
                />
              )}
              {countryOnly == null && (
                <Select
                  id="country"
                  className="selection-box"
                  options={newCountryData}
                  onChange={(value) =>
                    countryChangeHandler(value, "country", setFieldValue)
                  }
                  onBlur={handleBlur}
                  placeholder="Country*"
                  value={values.country}
                  closeMenuOnSelect={true}
                  closeMenuOnScroll={true}
                />
              )}
              <span className="error">
                {errors.country && touched.country && errors.country}
              </span>
            </div>
          </div>
          {/* {getFirstWordString(values.country)} */}
          <div className="from-inner-box">
            {" "}
            <div className="left-side">
              <label>
                Phone{" "}<i>*</i>
                <span className="font-size-11">
                  {/* (optional for text reminders) */}
                </span>
              </label>
              <PhoneInputField
                className={`number-input ${errors.phone && touched.phone && errors.phone
                  ? "error-boundry"
                  : ""
                  }`}
                country={userCountryCode.toLowerCase()}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                value={values.phone}
                id="phone"
                name="phone"
              />
              <span className="error">
                {errors.phone && touched.phone && errors.phone}
              </span>
            </div>
            <div className="right-side">
              <label>Whatsapp</label>
              <PhoneInputField
                className={`number-input ${errors.whatsapp && touched.whatsapp && errors.whatsapp
                  ? "error-boundry"
                  : ""
                  }`}
                country={userCountryCode.toLowerCase()}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                value={values.whatsapp}
                id="whatsapp"
                name="whatsapp"
              />
              {/* <input
                className={`${
                  errors.whatsapp && touched.whatsapp && errors.whatsapp
                    ? "error-boundry"
                    : ""
                }`}
                onChange={(e) =>
                  handleChangeHandler(e, "whatsapp", setFieldValue)
                }
                onBlur={handleBlur}
                value={values.whatsapp}
                maxlength="10"
                pattern="\d{10}"
                id="whatsapp"
                name="whatsapp"
                placeholder=""
                type="tel"
              /> */}
              <span className="error">
                {errors.whatsapp && touched.whatsapp && errors.whatsapp}
              </span>
            </div>
          </div>

          <div className="from-inner-box">
            <div className="full-fild">
              <label>
                Languages<i>*</i>
              </label>
              <Select
                id="languages"
                className="selection-box"
                options={newformData}
                multi={true}
                isMulti
                onChange={(value) => setFieldValue("languages", value)}
                onBlur={handleBlur}
                placeholder="Select languages"
                value={values.languages}
                closeMenuOnSelect={false}
                closeMenuOnScroll={false}
              />

              <span className="error">
                {errors.languages && touched.languages && errors.languages}
              </span>
            </div>
          </div>
          <div className="from-inner-box search-name">
            <label>
              Screen Name <i>*</i>
            </label>
            <input
              className={`${errors.screen_name && touched.screen_name && errors.screen_name
                ? "error-boundry"
                : ""
                }`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.screen_name}
              id="screen_name"
              name="screen_name"
              type="text"
              placeholder="Screen Name"
            />
            <h6>
              Your screen name and email are the only pieces of information
              accessible to our mediums, with&nbsp;
              <span className="text-decoration-underline pointer" onClick={() => setshowExpectionInfo(!showExpectionInfo)}>
                these exceptions.
              </span>
            </h6>
            {showExpectionInfo && (
              <span className="showexpectioninfo">
                <br />
                <span className="d-block text-left">Exceptions:</span>
                <ol>
                  <li>
                    if you add your phone number for the text reminders on the
                    booking page.
                  </li>
                  <li>
                    if you make a payment directly to the Medium to book a professional
                    session.
                  </li>
                </ol>
                <span className="d-block text-left">Our Mediums don’t use this information for
                  preparation or marketing of any kind.
                </span>
              </span>
            )}
          </div>

          <div className="from-inner-box cuntrey-website">
            <label>
              Timezone<i>*</i>
            </label>

            {timezoneOnly && (
              <Select
                id="timezone"
                className="selection-box"
                options={newTimezoneData}
                defaultValue={values.timezone}
                defaultInputValue={values.timezone.front_name}
                onChange={(value) => setFieldValue("timezone", value)}
                onBlur={handleBlur}
                placeholder="Select Your Timezone"
                value={values.timezone}
                closeMenuOnSelect={true}
                closeMenuOnScroll={true}
              />
            )}
            {timezoneOnly === null && (
              <Select
                id="timezone"
                className="selection-box"
                options={newTimezoneData}
                defaultValue={values.timezone}
                defaultInputValue={values.timezone.front_name}
                onChange={(value) => setFieldValue("timezone", value)}
                onBlur={handleBlur}
                placeholder="Select Your Timezone"
                value={values.timezone}
                closeMenuOnSelect={true}
                closeMenuOnScroll={true}
              />
            )}

            <span className="error">
              {timeZoneError && 'Required'}
            </span>
          </div>

          <button
            onClick={() => customHandleChange(errors)}
            type="submit"
            className="next action-button"
          // value="Next"
          >
            Next
          </button>
        </form>
      )}
    </Formik>
  );
};
export default StepOne;
