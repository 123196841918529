import React from 'react'
import TimeInput from './times'

export default function OnDemandUpdates() {
  return (
    <div className='d-flex justify-content-between w-70'>
      <div>
        <h3>Saturday</h3>
        <TimeInput />
      </div>
      <div>
        <h3>Sunday</h3>
        <TimeInput />
      </div>

    </div>
  )
}
