import React, { useEffect, useState } from "react";
import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
import Select from "react-select";
import { toTitleCase } from "../../../utils/helpers";
const list = [
    { id: 1, name: "Export", },
    { id: 2, name: "Download", },
];

const columnList = [
    'Id',
    'screen_name',
    'first_name',
    'last_name',
    'email',
    'phone',
    'whatsapp',
    'country',
    'agree_to_legal_terms_and_conditionss',
    'agree_to_starter_client_guidelines',
    'opt_in_out',
    'timezone',
    'referral_code',
    'created_at',
]



export default function ClientExport() {
    const [activeTab, setActiveTab] = useState(0);
    const [columnOptionList, setColumnOptionList] = useState([]);

    useEffect(() => {
        let list = columnList.map((item) => {
            return {
                value: item,
                label: toTitleCase(item),
            };
        })

        setColumnOptionList(list)
    }, [])
    return (
        <div className="all-medium-page">
            <Tabs active={activeTab} list={list} setActiveTab={setActiveTab} />
            <ul className="tabs-content">
                <TabPanel id={0} activeTab={activeTab}>
                    <div className="min-height-80vh">
                        <p className="margin-unset line-height-24px font-size-17">
                            <b> Choose client type  </b>
                        </p>
                        <div className='d-flex align-items-center mt-2'>

                            <input name='past_experience1' value={'Outreach Clients'} type='checkbox' id='yeswithverysoul' className='radio-btn-starter mr-2' />
                            <label htmlFor='yeswithverysoul' className='margin-unset'>Outreach Clients</label>
                        </div>
                        <div className='d-flex align-items-center mt-2'>

                            <input value={'Starter Clients'} name='past_experience2' type='checkbox' id='yesother' className='radio-btn-starter mr-2' />
                            <label htmlFor='yesother' className='margin-unset'>

                                Starter Clients
                            </label>
                        </div>
                        <div className='d-flex align-items-center mt-2'>

                            <input value={'Potential Clients'} name='past_experience3' type='checkbox' id='yesother1' className='radio-btn-starter mr-2' />
                            <label htmlFor='yesother1' className='margin-unset'>

                                Potential Clients
                            </label>
                        </div>
                        <div className='d-flex align-items-center mt-2'>

                            <input value={'Restricted Clients'} name='past_experience33' type='checkbox' id='yesother13' className='radio-btn-starter mr-2' />
                            <label htmlFor='yesother13' className='margin-unset'>

                                Restricted Clients
                            </label>
                        </div>
                        <div className='d-flex align-items-center mt-2'>

                            <input value={'Unverified Email'} name='past_experience333' type='checkbox' id='yesother133' className='radio-btn-starter mr-2' />
                            <label htmlFor='yesother13' className='margin-unset'>

                                Unverified Email
                            </label>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />

                        <div className="from-inner-box">
                            <div className="full-fild">
                                <p className="margin-unset line-height-24px font-size-17">
                                    <b> Choose Columns  </b>
                                </p>
                                <Select
                                    id="languages"
                                    className="selection-box"
                                    options={columnOptionList}
                                    multi={true}
                                    isMulti
                                    // onChange={(value) => setFieldValue("languages", value)}
                                    // onBlur={handleBlur}
                                    placeholder="Select Columns"
                                    // value={values.languages}
                                    closeMenuOnSelect={false}
                                    closeMenuOnScroll={false}
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />

                        <div>
                            <p className="margin-unset line-height-24px font-size-17">
                                <b> Appointment Count  </b>
                            </p>
                            <div className='d-flex align-items-center mt-2'>

                                <input name='past_experience4' value={'Upcoming'} type='checkbox' id='yeswithverysoull' className='radio-btn-starter mr-2' />
                                <label htmlFor='yeswithverysoull' className='margin-unset'>Upcoming</label>
                            </div>
                            <div className='d-flex align-items-center mt-2'>

                                <input value={'Cancelled by client'} name='past_experience5' type='checkbox' id='yesother5' className='radio-btn-starter mr-2' />
                                <label htmlFor='yesother5' className='margin-unset'>

                                    Cancelled by client
                                </label>
                            </div>
                            <div className='d-flex align-items-center mt-2'>

                                <input value={'Cancelled by medium'} name='past_experience6' type='checkbox' id='yesother6' className='radio-btn-starter mr-2' />
                                <label htmlFor='yesother6' className='margin-unset'>

                                    Cancelled by medium
                                </label>
                            </div>
                            <div className='d-flex align-items-center mt-2'>

                                <input value={'No showed by client'} name='past_experience7' type='checkbox' id='yesother7' className='radio-btn-starter mr-2' />
                                <label htmlFor='yesother7' className='margin-unset'>

                                    No showed by client
                                </label>
                            </div>
                            <div className='d-flex align-items-center mt-2'>

                                <input value={'No show by medium'} name='past_experience8' type='checkbox' id='yesother8' className='radio-btn-starter mr-2' />
                                <label htmlFor='yesother8' className='margin-unset'>

                                    No show by medium
                                </label>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div>
                            <p className="margin-unset line-height-24px font-size-17">
                                <b> Session Type  </b>
                            </p>
                            <div className='d-flex align-items-center mt-2'>

                                <input name='past_experience4Donated' value={'Donated'} type='checkbox' id='yeswithverysoullDonated' className='radio-btn-starter mr-2' />
                                <label htmlFor='yeswithverysoullDonated' className='margin-unset'>Donated</label>
                            </div>
                            <div className='d-flex align-items-center mt-2'>

                                <input value={'Starter'} name='past_experience5Starter' type='checkbox' id='yesother5Starter' className='radio-btn-starter mr-2' />
                                <label htmlFor='yesother5Starter' className='margin-unset'>

                                    Starter
                                </label>
                            </div>
                            <div className='d-flex align-items-center mt-2'>

                                <input value={'Cancelled by medium'} name='past_experience6' type='checkbox' id='yesother6' className='radio-btn-starter mr-2' />
                                <label htmlFor='yesother6' className='margin-unset'>

                                    Cancelled by medium
                                </label>
                            </div>
                            <div className='d-flex align-items-center mt-2'>

                                <input value={'Professional'} name='past_experience7Professional' type='checkbox' id='yesother7Professional' className='radio-btn-starter mr-2' />
                                <label htmlFor='yesother7Professional' className='margin-unset'>

                                    Professional
                                </label>
                            </div>
                            <div className='d-flex align-items-center mt-2 ml-5'>

                                <input value={'Pay After'} name='past_experience8after' type='checkbox' id='yesother8after' className='radio-btn-starter mr-2' />
                                <label htmlFor='yesother8after' className='margin-unset'>

                                    Pay After
                                </label>
                            </div>
                            <div className='d-flex align-items-center mt-2 ml-5'>

                                <input value={'Pay Before'} name='past_experience8afterw' type='checkbox' id='yesother8afterw' className='radio-btn-starter mr-2' />
                                <label htmlFor='yesother8afterws' className='margin-unset'>

                                    Pay Before
                                </label>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div>
                            <p className="margin-unset line-height-24px font-size-17">
                                <b> Client Statuses </b>
                            </p>
                            <div className='d-flex align-items-center mt-2'>
                                <select className="height-45">

                                    <option>Profile completed</option>
                                    <option>Profile incompleted</option>
                                    <option>Unverified Email</option>
                                </select>

                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </TabPanel>
                <TabPanel id={1} activeTab={activeTab}>
                    <div className="min-height-80vh">
                    </div>
                </TabPanel>
            </ul>
        </div>
    );
}
